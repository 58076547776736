import React, { Component } from "react";
import {
  Card,
  Typography,
  Button,
  Table,
  Pagination,
  Select,
  Skeleton,
  Tag,
} from "antd";
import axios from "axios";
// import { colors } from "../colors.js";
import FileSaver from "file-saver";
// import swal from "sweetalert2";
import moment from "moment";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { ANALYTICS_API, USER_PROFILE } from "../../api/constants";
import {
  Chart as ChartJS,
  ArcElement,
  // CategoryScale,
  // LinearScale,
  // PointElement,
  // BarElement,
  // LineElement,
  Tooltip,
  Legend,
  // Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import "../login.css";

const { Text, Title } = Typography;
const { Column } = Table;
const { Option } = Select;
// const { CheckableTag } = Tag;

ChartJS.register(ArcElement, Tooltip, Legend);

class Target extends Component {
  state = {
    date_to_use: "created_on",
    user_org_id: "",
    loading: false,
    total: 0,
    page: 1,
    limit: 10,
    target_chart: [],
    targets: [],
    target_type: "sales",
    achivement_for: "order",
    duration_type: "MONTHLY",
    target_id: "",
  };
  componentDidMount() {
    this.getUserdata();
  }

  getUserdata = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USER_PROFILE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user profile", response);

          this.setState(
            {
              user_org_id: response.data.organization.$oid,
            },
            () => {
              this.getTargetList();
            }
          );
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from user profile", error);
        });
    });
  };

  getTargetList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}target_list_api/${this.state.user_org_id}?target_type=${this.state.target_type}&achivement_for=${this.state.achivement_for}&duration_type=${this.state.duration_type}&limit=${this.state.limit}&page=${this.state.page}&date_to_use=${this.state.date_to_use}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("target list", response);
          this.setState(
            {
              loading: false,
              targets: response.data?.targets ?? [],
              total: response.data?.total ?? 0,
            },
            () => {
              if (response.data?.targets?.length > 0) {
                this.setState(
                  { target_id: response.data.targets[0]._id },
                  () => {
                    this.getTargetChart();
                  }
                );
              }
            }
          );
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("target list", error);
        });
    });
  };
  getTargetChart = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}target_achivement_generator/${this.state.user_org_id}?target_id=${this.state.target_id}&date_to_use=created_on&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("target bar chart", response);
          this.setState({
            loading: false,
            target_chart: response.data?.data ?? [],
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("target bar chart", error);
        });
    });
  };

  downloadTargetChartExcel = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}target_achivement_generator/${this.state.user_org_id}?fmt=xl&target_id=${this.state.target_id}&date_to_use=created_on&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // console.log("top ten sells person report", blob);

          FileSaver.saveAs(
            blob,
            `target_achievement_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("target achievement report api", error);
        });
    });
  };
  downloadTargetChartPdf = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}target_achivement_generator/${this.state.user_org_id}?fmt=bar_pdf&target_id=${this.state.target_id}&date_to_use=created_on&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // console.log("top ten sells person report", blob);

          FileSaver.saveAs(
            blob,
            `target_achievement_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
        })
        .catch((error) => {
          console.log("target achievement report api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current }, () => {
      console.log("page number", this.state.page);
      this.getTargetList();
    });
  };

  setLimitStatusList = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getTargetList();
    });
  };

  render() {
    if (this.state.loading === true) {
      return (
        <Skeleton
          paragraph={{
            rows: 20,
          }}
          active
        />
      );
    }
    return (
      <div style={{ marginTop: 10 }}>
        <Card>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                marginRight: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Target Type : </Text>
              <Select
                value={this.state.target_type}
                onChange={(value) => {
                  this.setState({ target_type: value }, () => {
                    this.getTargetList();
                    console.log(this.state.target_type);
                  });
                }}
                style={{ minWidth: 150 }}
              >
                <Option key="sales">Sales</Option>
                <Option key="operations">Operations</Option>
                <Option key="customer_service ">Customer Service</Option>
                <Option key="financial">Financial</Option>
                <Option key="attendance">Attendance</Option>
              </Select>
            </div>
            <div
              style={{
                marginRight: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Achievment For : </Text>
              <Select
                value={this.state.achivement_for}
                onChange={(value) => {
                  this.setState({ achivement_for: value }, () => {
                    this.getTargetList();
                    console.log(this.state.achivement_for);
                  });
                }}
                style={{ minWidth: 150 }}
              >
                <Option key="order">Order</Option>
                <Option key="task">Task</Option>
              </Select>
            </div>
            <div
              style={{
                marginRight: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Duration Type : </Text>
              <Select
                value={this.state.duration_type}
                onChange={(value) => {
                  this.setState({ duration_type: value }, () => {
                    this.getTargetList();
                    console.log(this.state.duration_type);
                  });
                }}
                style={{ minWidth: 150 }}
              >
                <Option key="WEEKLY">WEEKLY</Option>
                <Option key="MONTHLY">MONTHLY</Option>
                <Option key="DAILY">DAILY</Option>
                <Option key="CUSTOM">CUSTOM</Option>
              </Select>
            </div>
          </div>
        </Card>

        <Card style={{ marginBottom: 5, marginTop: 5 }}>
          <Title level={4}>Target List</Title>

          <Table
            dataSource={this.state.targets}
            pagination={false}
            rowKey={(record) => record._id}
            size="small"
          >
            <Column
              title={
                <>
                  <Text ellipsis={true}>Name</Text>
                </>
              }
              key="name"
              render={(text, record) => <span>{`${record?.name ?? ""}`}</span>}
            />
            <Column
              title={
                <>
                  <Text ellipsis={true}>Target Value</Text>
                </>
              }
              key="target_value"
              render={(text, record) => (
                <span>{`${record?.target_value ?? ""}`}</span>
              )}
            />

            <Column
              title={
                <>
                  <Text ellipsis={true}>Target Type</Text>
                </>
              }
              key="target_type"
              render={(text, record) => (
                <span>{`${record.target_type ?? ""}`}</span>
              )}
            />
            <Column
              title={
                <>
                  <Text ellipsis={true}>Target For</Text>
                </>
              }
              key="target_for"
              render={(text, record) => (
                <span>{`${record?.target_for ?? ""}`}</span>
              )}
            />
            <Column
              title={
                <>
                  <Text ellipsis={true}>Assigned To</Text>
                </>
              }
              key="assigned_to"
              render={(text, record) => (
                <span>
                  {record?.assigned_to?.user?.map((r) => (
                    <Tag key={r.user_id} color="blue">
                      {r.name}
                    </Tag>
                  ))}
                </span>
              )}
            />
            <Column
              title={
                <>
                  <Text ellipsis={true}>Start Time</Text>
                </>
              }
              key="start_time"
              render={(text, record) => (
                <span>{`${record?.start_time ?? ""}`}</span>
              )}
            />
            <Column
              title={
                <>
                  <Text ellipsis={true}>End Time</Text>
                </>
              }
              key="end_time"
              render={(text, record) => (
                <span>{`${record?.end_time ?? ""}`}</span>
              )}
            />
            <Column
              title={
                <>
                  <Text ellipsis={true}>Duration Type</Text>
                </>
              }
              key="duration_type"
              render={(text, record) => (
                <span>{`${record?.duration_type ?? ""}`}</span>
              )}
            />
            <Column
              title={
                <>
                  <Text ellipsis={true}>Created On</Text>
                </>
              }
              key="created_on"
              render={(text, record) => <span>{`${record?.created_on}`}</span>}
            />
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <span>
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() =>
                      this.setState({ target_id: record._id }, () =>
                        this.getTargetChart()
                      )
                    }
                  >
                    View Chart
                  </Button>
                </span>
              )}
            />
          </Table>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.limit)}
              current={this.state.page}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ marginTop: 10 }}
            />
          ) : null}
        </Card>

        <Card style={{ width: "100%" }}>
          <Button type="link" onClick={this.downloadTargetChartExcel}>
            Download Excel
          </Button>
          <Button type="link" onClick={this.downloadTargetChartPdf}>
            Download PDF
          </Button>
          <Bar
            style={{
              marginBottom: 20,
              height: this.state.target_chart.length < 5 ? "300px" : "auto",
            }}
            data={{
              labels: this.state.target_chart.map((r) => r.target_from),
              datasets: [
                {
                  data: this.state.target_chart.map((r) => r.target_value),
                  backgroundColor: "#FB9EC6",
                  label: "Target",
                  barThickness:
                    this.state.target_chart.length < 5 ? 40 : "flex",
                },
                {
                  data: this.state.target_chart.map((r) => r.achievement),
                  backgroundColor: "#FBB4A5",
                  label: "Achievement",
                  barThickness:
                    this.state.target_chart.length < 5 ? 40 : "flex",
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              scales: {
                x: {
                  ticks: { autoSkip: false },
                },
              },
            }}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  getUserData,
})(Target);
