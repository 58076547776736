import React, { Component } from "react";
import {
  Card,
  Typography,
  Button,
  Table,
  Pagination,
  Select,
  Skeleton,
  Tag,
  Progress,
} from "antd";
import axios from "axios";
import CardMaterial from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FileSaver from "file-saver";

import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
import {
  ANALYTICS_API,
  CREATE_TASK_TYPE,
  USER_PROFILE,
  CURRENT_USER_SUB_ORG_LIST,
  CREATE_DRIVER,
} from "../../api/constants";
import { debounce } from "lodash";
import { colors } from "../colors.js";

import "../login.css";

const { Text, Title } = Typography;
const { Column } = Table;
const { Option } = Select;
const { CheckableTag } = Tag;

class Task extends Component {
  state = {
    start_time: moment().subtract(30, "days").format("x"),
    start_time_view: moment().subtract(30, "days"),
    end_time: moment().format("x"),
    end_time_view: moment(),
    date_to_use: "created_on",
    task_types: [],
    task_type: "",
    total_tasks: 0,
    pending_tasks: 0,
    accepted_tasks: 0,
    started_tasks: 0,
    completed_tasks: 0,
    farmer_count: 0,
    prescription_count: 0,
    task_data: [],
    user_org_id: "",
    page: 1,
    page_size: 10,
    total: 0,
    target_achievement_page: 1,
    target_achievement_page_size: 10,
    target_achievement_total: 0,
    target_achievement_data: [],
    individual_users: [],
    individual_user_id: "",
    loading: false,
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    sub_org_id_task_search: "",
    seven_days: false,
    thirty_days: false,
    last_month: false,
    this_month: false,
    duration_type: "WEEKLY",
    top_ten_users: [],
  };
  componentDidMount() {
    this.getCurrentUserSubOrgs();

    this.getTaskType();
    this.getUserList();
  }

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user list", response);
          this.setState({
            individual_users: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  };

  handleSearchUser = debounce((value) => {
    console.log("search text", value);
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from search user", response);
          this.setState({
            individual_users: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_task_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getUserdata();
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  handleSuborgSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState({
            current_user_sub_orgs: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  }, 500);

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_task_search: value },
      () => {
        this.getUserdata();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current }, () => {
      console.log("page number", this.state.page);
      this.getTasksTable();
    });
  };
  targetPageChange = (current, pageSize) => {
    this.setState({ target_achievement_page: current }, () => {
      console.log("page number", this.state.target_achievement_page);
      this.getTargetAchievementTable();
    });
  };

  setTargetLimit = (value) => {
    this.setState({ target_achievement_page_size: value }, () => {
      console.log(this.state.target_achievement_page_size);
      this.getTargetAchievementTable();
    });
  };
  setLimit = (value) => {
    this.setState({ page_size: value }, () => {
      console.log(this.state.page_size);
      this.getTasksTable();
    });
  };

  getUserdata = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USER_PROFILE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user profile", response);

          this.setState(
            {
              user_org_id: response.data.organization.$oid,
            },
            () => {
              this.getTotalTasks();
              this.getTasksTable();
              this.getTargetAchievementTable();
            }
          );
        })
        .catch((error) => {
          console.log("error from user profile", error);
        });
    });
  };

  getTaskType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_TASK_TYPE}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from task type list", response);

          this.setState({
            task_types: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from task type list api", error);
        });
    });
  };

  getTotalTasks = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}tasks/total_tasks?task_type=${
        this.state.task_type
      }&sub_organization=${this.state.sub_org_id_task_search}&user_id=${
        GET_USER_LOCAL()._id.$oid
      }&organization_id=${this.state.user_org_id}&start_date=${
        this.state.start_time
      }&end_date=${this.state.end_time}&date_to_use=${
        this.state.date_to_use
      }&access_token=${token}`;
      // const url = `https://service.conveyance.app/fastapi/tasks/hiearchy_level?user_id=${GET_USER_LOCAL()._id.$oid}&sub_organization=${this.state.sub_org_id_task_search}&task_type=${this.state.task_type}&organization_id=${this.state.user_org_id}&start_date=${this.state.start_time}&end_date=${this.state.end_time}&date_to_use=${this.state.date_to_use}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from total tasks chart api", response);
          this.setState({
            total_tasks: response.data.total_tasks ?? 0,
            pending_tasks: response.data.pending_tasks ?? 0,
            accepted_tasks: response.data.accepted_tasks ?? 0,
            started_tasks: response.data.started_tasks ?? 0,
            completed_tasks: response.data.completed_tasks ?? 0,
            farmer_count: response.data.farmer_count ?? 0,
            prescription_count: response.data.prescription_count ?? 0,
          });
        })
        .catch((error) => {
          console.log("error from total tasks chart api", error);
        });
    });
  };
  getTasksTable = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}tasks/table?page_size=${
        this.state.page_size
      }&page=${this.state.page}&sub_organization=${
        this.state.sub_org_id_task_search
      }&organization_id=${this.state.user_org_id}&user_id=${
        GET_USER_LOCAL()._id.$oid
      }&start_date=${this.state.start_time}&end_date=${
        this.state.end_time
      }&date_to_use=${this.state.date_to_use}&task_type=${
        this.state.task_type
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from tasks table chart api", response);
          this.setState({
            task_data: response.data?.filtered_data ?? [],
            top_ten_users: response.data?.top_10_created_by_list ?? [],
            total: response.data?.total_count ?? 0,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from tasks table chart api", error);
        });
    });
  };

  downloadTaskReport = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}tasks/table?page_size=${
        this.state.page_size
      }&page=${this.state.page}&download=true&sub_organization=${
        this.state.sub_org_id_task_search
      }&organization_id=${this.state.user_org_id}&user_id=${
        GET_USER_LOCAL()._id.$oid
      }&start_date=${this.state.start_time}&end_date=${
        this.state.end_time
      }&date_to_use=${this.state.date_to_use}&task_type=${
        this.state.task_type
      }&access_token=${token}`
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("recent task report", blob);

          FileSaver.saveAs(
            blob,
            `recent_task_summary_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.csv`
          );
        })
        .catch((error) => {
          console.log("recent task report", error);
        });
    });
  };

  getTargetAchievementTable = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}target_achivement/${
        this.state.user_org_id
      }?page_size=${this.state.target_achievement_page_size}&page=${
        this.state.target_achievement_page
      }&sub_organization=${this.state.sub_org_id_task_search}&user_id=${
        GET_USER_LOCAL()._id.$oid
      }&individual_user=${this.state.individual_user_id}&start_date=${
        this.state.start_time
      }&end_date=${this.state.end_time}&duration_type=${
        this.state.duration_type
      }&achivement_for=Task&date_to_use=${
        this.state.date_to_use
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log(
            "response from target achievement table chart api",
            response
          );
          this.setState({
            target_achievement_data: response.data.data,
            target_achievement_total: response.data.total_count,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from target achievement table chart api", error);
        });
    });
  };

  setTaskType = (value) => {
    this.setState({ task_type: value }, () => {
      this.getTotalTasks();
      this.getTasksTable();
      console.log(this.state.task_type);
    });
  };

  setUser = (value) => {
    this.setState({ individual_user_id: value }, () => {
      this.getTargetAchievementTable();
      console.log(this.state.individual_user_id);
    });
  };

  render() {
    if (this.state.loading === true) {
      return (
        <Skeleton
          paragraph={{
            rows: 20,
          }}
          active
        />
      );
    }
    return (
      <div style={{ marginTop: 10 }}>
        <Card>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  // minDate={moment().subtract(30, "days")}
                  style={{ marginTop: 15 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14,
                      width: 130,
                    },
                  }}
                  disableFuture
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).startOf("day").format("x"),
                        start_time_view: value,
                        end_time: moment(value)
                          .add(30, "days")
                          .endOf("day")
                          .format("x"),
                        end_time_view: moment(value)
                          .add(30, "days")
                          .endOf("day"),
                      },
                      () => {
                        this.getTotalTasks();
                        this.getTasksTable();
                        this.getTargetAchievementTable();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  // minDate={moment().subtract(30, "days")}
                  style={{ marginTop: 15 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14,
                      width: 130,
                    },
                  }}
                  disableFuture
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    if (
                      (value - this.state.start_time_view) /
                        (1000 * 3600 * 24) >
                      30
                    ) {
                      swal.fire(
                        "Info",
                        "Date range must be within 30 days",
                        "info"
                      );
                    } else {
                      this.setState(
                        {
                          end_time: moment(value)
                            .endOf("day")
                            .add(59, "seconds")
                            .format("x"),
                          end_time_view: value,
                        },
                        () => {
                          this.getTotalTasks();
                          this.getTasksTable();
                          this.getTargetAchievementTable();
                        }
                      );
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 5,
                marginLeft: 4,
              }}
            >
              <Text>Filter Time By : </Text>
              <Select
                value={this.state.date_to_use}
                onChange={(value) => {
                  this.setState({ date_to_use: value }, () => {
                    this.getTotalTasks();
                    this.getTasksTable();
                    this.getTargetAchievementTable();
                    console.log(this.state.date_to_use);
                  });
                }}
                style={{ minWidth: 100 }}
              >
                <Option key="created_on">Created On</Option>
                <Option key="completed_on">Completed On</Option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 5,
              }}
            >
              <Text>Task Type : </Text>
              <Select
                value={this.state.task_type}
                onChange={this.setTaskType}
                placeholder="Task Type"
                style={{ minWidth: 200 }}
              >
                {this.state.task_types.map((r) => {
                  return <Option key={r.name}>{r.name}</Option>;
                })}
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text> User Sub Organization : {""}</Text>
              <Select
                style={{ minWidth: 200 }}
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleSuborgSearch}
                // disabled={
                //   this.state.current_user_sub_orgs.length < 1 ? true : false
                // }
                value={this.state.current_user_sub_org_id}
                onChange={this.setCurrentUserSubOrg}
              >
                {this.state.current_user_sub_orgs.map((r) => {
                  return <Option key={r.id}>{`${r.name}`}</Option>;
                })}
              </Select>
            </div>
            {this.props.userProfileData.data_level.access_all_data ? (
              <Button
                type="link"
                style={{ marginTop: 20 }}
                onClick={() => {
                  this.setState(
                    {
                      current_user_sub_org_id: "",
                      sub_org_id_task_search: "",
                      task_type: "",
                    },
                    () => {
                      this.getCurrentUserSubOrgs();
                    }
                  );
                }}
              >
                Reset
              </Button>
            ) : null}
          </div>
          <div style={{ marginLeft: 5 }}>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.seven_days ? "#1A3337" : "",
              }}
              checked={this.state.seven_days}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: checked,
                  thirty_days: false,
                  last_month: false,
                  this_month: false,
                });

                if (checked) {
                  this.setState(
                    {
                      start_time: moment().subtract(7, "days").format("x"),
                      start_time_view: moment().subtract(7, "days"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalTasks();
                      this.getTasksTable();
                      this.getTargetAchievementTable();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalTasks();
                      this.getTasksTable();
                      this.getTargetAchievementTable();
                    }
                  );
                }
              }}
            >
              Last 7 Days
            </CheckableTag>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.thirty_days ? "#1A3337" : "",
              }}
              checked={this.state.thirty_days}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: false,
                  thirty_days: checked,
                  last_month: false,
                  this_month: false,
                });
                if (checked) {
                  this.setState(
                    {
                      start_time: moment().subtract(30, "days").format("x"),
                      start_time_view: moment().subtract(30, "days"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalTasks();
                      this.getTasksTable();
                      this.getTargetAchievementTable();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalTasks();
                      this.getTasksTable();
                      this.getTargetAchievementTable();
                    }
                  );
                }
              }}
            >
              Last 30 Days
            </CheckableTag>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.this_month ? "#1A3337" : "",
              }}
              checked={this.state.this_month}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: false,
                  thirty_days: false,
                  last_month: false,
                  this_month: checked,
                });
                if (checked) {
                  this.setState(
                    {
                      start_time: moment().startOf("month").format("x"),
                      start_time_view: moment().startOf("month"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalTasks();
                      this.getTasksTable();
                      this.getTargetAchievementTable();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalTasks();
                      this.getTasksTable();
                      this.getTargetAchievementTable();
                    }
                  );
                }
              }}
            >
              Current Month
            </CheckableTag>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.last_month ? "#1A3337" : "",
              }}
              checked={this.state.last_month}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: false,
                  thirty_days: false,
                  last_month: checked,
                  this_month: false,
                });
                if (checked) {
                  this.setState(
                    {
                      start_time: moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .format("x"),
                      start_time_view: moment()
                        .subtract(1, "month")
                        .startOf("month"),
                      end_time: moment()
                        .subtract(1, "month")
                        .endOf("month")
                        .format("x"),
                      end_time_view: moment()
                        .subtract(1, "month")
                        .endOf("month"),
                    },
                    () => {
                      this.getTotalTasks();
                      this.getTasksTable();
                      this.getTargetAchievementTable();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getTotalTasks();
                      this.getTasksTable();
                      this.getTargetAchievementTable();
                    }
                  );
                }
              }}
            >
              Last Month
            </CheckableTag>
          </div>
        </Card>
        <Card style={{ marginTop: 5 }}>
          <div
            className="cardscontainer"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: `5px solid ${colors[0]}`,
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Total
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: `${colors[0]}` }}
                  >{`${this.state.total_tasks}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: `5px solid ${colors[1]}`,
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Pending
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: `${colors[1]}` }}
                  >{`${this.state.pending_tasks}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: `5px solid ${colors[2]}`,
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Accepted
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: `${colors[2]}` }}
                  >{`${this.state.accepted_tasks}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: `5px solid ${colors[3]}`,
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Started
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: `${colors[3]}` }}
                  >{`${this.state.started_tasks}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: `5px solid ${colors[4]}`,
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Completed
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: `${colors[4]}` }}
                  >{`${this.state.completed_tasks}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            {this.state.user_org_id === "65c460e865487463b647aa7d" ? (
              <CardMaterial
                className="cardsingle"
                style={{
                  borderRadius: 15,
                  borderLeft: `5px solid ${colors[5]}`,
                  width: "50%",
                  maxHeight: 150,
                  margin: 5,
                }}
              >
                <CardContent>
                  <div style={{ textAlign: "center" }}>
                    <Text
                      style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                    >
                      Prescription Count
                    </Text>

                    <Title
                      style={{ marginTop: 5, color: `${colors[5]}` }}
                    >{`${this.state.prescription_count}`}</Title>
                  </div>
                </CardContent>
              </CardMaterial>
            ) : this.state.user_org_id === "62b2aae9e71f1f18fa519220" ? (
              <CardMaterial
                className="cardsingle"
                style={{
                  borderRadius: 15,
                  borderLeft: `5px solid ${colors[6]}`,
                  width: "50%",
                  maxHeight: 150,
                  margin: 5,
                }}
              >
                <CardContent>
                  <div style={{ textAlign: "center" }}>
                    <Text
                      style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                    >
                      Farmer Count
                    </Text>

                    <Title
                      style={{ marginTop: 5, color: `${colors[6]}` }}
                    >{`${this.state.farmer_count}`}</Title>
                  </div>
                </CardContent>
              </CardMaterial>
            ) : null}
          </div>
        </Card>
        {/* badges end here */}
        <Card style={{ marginTop: 5, width: "100%" }}>
          <Title style={{ marginLeft: 15 }} level={3}>
            Recent Tasks
          </Title>
          <div style={{ display: "flex" }}>
            <div style={{ margin: 10 }}>
              <Text>{`Displaying : `}</Text>
              <Select value={this.state.page_size} onChange={this.setLimit}>
                <Option key={10}>10</Option>
                <Option key={25}>25</Option>
                <Option key={50}>50</Option>
                <Option key={100}>100</Option>
              </Select>
              <Text>{`  Entries `}</Text>
            </div>
            <Button style={{ marginTop: 10 }} onClick={this.downloadTaskReport} type="link">
              Download Report
            </Button>
          </div>

          <Table
            dataSource={this.state.task_data}
            pagination={false}
            rowKey={(record) => record._id}
            size="small"
          >
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text ellipsis={true}>Task Code</Text>
                  </div>
                </>
              }
              key="task_code"
              render={(text, record) => (
                <span>{`${record?.task_code ?? ""}`}</span>
              )}
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text ellipsis={true}>Name</Text>
                  </div>
                </>
              }
              key="name"
              render={(text, record) => <span>{`${record?.name ?? ""}`}</span>}
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text ellipsis={true}>Task Type</Text>
                  </div>
                </>
              }
              key="task_type"
              render={(text, record) => (
                <span>{`${record?.task_type ?? ""}`}</span>
              )}
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text ellipsis={true}>Price</Text>
                  </div>
                </>
              }
              key="price"
              render={(text, record) => <span>{`${record?.price ?? ""}`}</span>}
            />

            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text ellipsis={true}>Completed</Text>
                  </div>
                </>
              }
              key="completed"
              render={(text, record) => (
                <span>{`${record?.completed.toString() ?? ""}`}</span>
              )}
            />

            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text ellipsis={true}>Completed On</Text>
                  </div>
                </>
              }
              key="completed_on"
              render={(text, record) => (
                <span>
                  {record.completed_on
                    ? timeConverter(record?.completed_on)
                    : ""}
                </span>
              )}
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text ellipsis={true}>Assigned To</Text>
                  </div>
                </>
              }
              key="assigned_to_name"
              render={(text, record) => (
                <span>{record.assigned_to_name ?? ""}</span>
              )}
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text ellipsis={true}>Created By</Text>
                  </div>
                </>
              }
              key="created_by_name"
              render={(text, record) => (
                <span>{record.created_by_name ?? ""}</span>
              )}
            />
            <Column
              title="Created on"
              key="created_on"
              render={(text, record) => (
                <span>
                  {record.created_on ? timeConverter(record?.created_on) : ""}
                </span>
              )}
            />
          </Table>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.page_size)}
              current={this.state.page}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ marginTop: 10 }}
            />
          ) : null}
        </Card>
        <div style={{ display: "flex" }}>
          <Card style={{ marginTop: 5, marginRight: 5, width: "100%" }}>
            <Title style={{ marginLeft: 15 }} level={3}>
              Top 10 Performers
            </Title>
            {this.state.top_ten_users.map((user) => (
              <div key={user.created_by}>
                <Text
                  style={{
                    fontSize: 20,
                  }}
                >
                  {user.name}
                </Text>
                <Progress
                  style={{
                    fontSize: 20,
                  }}
                  percent={user.task_count_by_created_by }
                  format={() => user.task_count_by_created_by}
                  strokeColor="#00712D"
                  status="active"
                />
              </div>
            ))}
          </Card>
          <Card style={{ marginTop: 5, width: "100%" }}>
            <Title style={{ marginLeft: 15 }} level={3}>
              Recent Achievements
            </Title>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ margin: 10 }}>
                <Text>{`Displaying : `}</Text>
                <Select
                  style={{ minWidth: 100 }}
                  value={this.state.target_achievement_page_size}
                  onChange={this.setTargetLimit}
                >
                  <Option key={10}>10</Option>
                  <Option key={25}>25</Option>
                  <Option key={50}>50</Option>
                  <Option key={100}>100</Option>
                </Select>
              </div>
              <div style={{ margin: 10 }}>
                <Text>{`Search User : `}</Text>
                <Select
                  style={{ width: 200 }}
                  value={this.state.individual_user_id}
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearchUser}
                  onChange={this.setUser}
                  placeholder="Search User"
                >
                  {this.state.individual_users.map((r) => {
                    return (
                      <Option
                        key={r._id.$oid}
                      >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                    );
                  })}
                </Select>
              </div>
              <div style={{ margin: 10 }}>
                <Text>Duration Type : </Text>
                <Select
                  value={this.state.duration_type}
                  onChange={(value) => {
                    this.setState({ duration_type: value }, () => {
                      this.getTargetAchievementTable();
                      console.log(this.state.duration_type);
                    });
                  }}
                  style={{ minWidth: 100 }}
                >
                  <Option key="DAILY">DAILY</Option>
                  <Option key="WEEKLY">WEEKLY</Option>
                  <Option key="MONTHLY">MONTHLY</Option>
                </Select>
              </div>
            </div>
            <Table
              dataSource={this.state.target_achievement_data}
              pagination={false}
              rowKey={(record) => record.user_id}
              size="small"
            >
              <Column
                title="Name"
                key="name"
                render={(text, record) => (
                  <span>{`${record?.name ?? ""}`}</span>
                )}
              />
              <Column
                title="Month"
                key="month"
                render={(text, record) => (
                  <span>{`${record?.month ?? ""}`}</span>
                )}
              />
              <Column
                title="Target Value"
                key="target_value"
                render={(text, record) => (
                  <span>{`${record?.target_value ?? 0}`}</span>
                )}
              />
              <Column
                title="Achievement Percentage"
                key="achievement_percentage"
                render={(text, record) => (
                  <span>{`${record?.achievement_percentage ?? 0}`}</span>
                )}
              />
            </Table>
            {this.state.target_achievement_total > 10 ? (
              <Pagination
                pageSize={Number(this.state.target_achievement_page_size)}
                current={this.state.target_achievement_page}
                total={this.state.target_achievement_total}
                onChange={this.targetPageChangepageChange}
                style={{ marginTop: 10 }}
              />
            ) : null}
          </Card>
        </div>

        {/**Tables end here */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  getUserData,
})(Task);
